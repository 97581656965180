import React from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

const ExcelExportHelper = (props) => {

  const {
    reportData = {}, 
    summaryData = {},
    le = "",
    month = "",
    year = "",
    MONTH_DAYS = 31,
    daysArray = []
  } = props;

  console.log(reportData);

  const keyArray = ["A", "B", "C", "D", "E", "F","G","H","I","J","K","L","M","N","O","P","Q", "R", "S","T","U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM"];

  const DATES_IN_MONTH = MONTH_DAYS;

  const WIDER_CELL_WIDTH = 5;
  const EACH_CELL_WIDTH = 3.2;

  const REPORT_PAGE_START_CELLS = 2;
  const REPORT_PAGE_END_CELLS = 1;

  const SUMMARY_PAGE_START_CELLS = 3;
  const SUMMARY_PAGE_END_CELLS = 4;

  const SUMMARY_WIDER_CELL_WIDTH = 10;
  const SUMMARY_EACH_CELL_WIDTH = 4;

  const createDownLoadData = () => {
    handleExport().then((url) => {

      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute("download", `${reportData.le_name}_Report_${reportData.label_month}_${reportData.label_year}.xlsx`);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);

    // The application/octet-stream MIME type is used for unknown binary files.
    // It preserves the file contents, but requires the receiver to determine file type,
    // for example, from the filename extension.
    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    // The ArrayBuffer() constructor is used to create ArrayBuffer objects.
    // create an ArrayBuffer with a size in bytes
    const buf = new ArrayBuffer(s.length);

    //create a 8 bit integer array
    const view = new Uint8Array(buf);

    //charCodeAt The charCodeAt() method returns an integer between 0 and 65535 representing the UTF-16 code
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const createReportTab = () =>  {
    const days = createHeader(2, 0);
    let header = [{A: reportData.label_header_1_1, B: reportData.label_header_1_2, ...days}]

    let MonthDetails = [
      {
        A: `${reportData.label_report_month} ${reportData.label_month} ${reportData.label_year}`
      }
    ]

    let table = [];
   
    reportData.attendance_data.forEach((row, index) => {
      const studentDetails = row;

      let nameTable = [];
      let table1 = [];
      let table2 = [];
      let table3 = [];
      
      nameTable.push({
        A: studentDetails.emp_id,
        B: studentDetails.emp_branch,
        D: studentDetails.emp_name,
        M: `${studentDetails.emp_designation} - ${studentDetails.emp_jobrole}`,
        V: `W/D - ${studentDetails.daysPresentForTheMonth}`,
        Y: `W/O - ${studentDetails.daysLeaveforTheMonth}`,
        AC: `AB - ${studentDetails.daysAbsentForTheMonth}`
      });

      const in_time_obj = [];
      const out_time_obj = [];
      const ot_time_obj = [];

      in_time_obj["B"] = studentDetails.emp_branch;
      out_time_obj["B"] = studentDetails.emp_branch;
      ot_time_obj["B"] = studentDetails.emp_branch;

      for(let i = 1; i<= daysArray.length; i++) {
        in_time_obj[keyArray[i + 1]] =  getReportDataByKey(studentDetails.dates, daysArray[i-1], "in_time", i-1);
        out_time_obj[keyArray[i + 1]] =  getReportDataByKey(studentDetails.dates, daysArray[i-1], "out_time", i-1);
        ot_time_obj[keyArray[i + 1]] =  getReportDataByKey(studentDetails.dates, daysArray[i-1], "ot_hrs", i-1);
      }

      ot_time_obj[keyArray[DATES_IN_MONTH + 2]] = studentDetails.total_ot_hrs;
      
      table1.push({A: studentDetails.label_intime,  B: "", ...in_time_obj});
      table2.push({A: studentDetails.label_outtime, B: "", ...out_time_obj});
      table3.push({A: studentDetails.label_ot_hrs  ,B: "", ...ot_time_obj});

      table = table
      .concat(nameTable)
      .concat(table1)
      .concat(table2)
      .concat(table3);
    });

    
    const title = [{ A: `${reportData.le_name}`}, {}];
    const officeDetails = [{ A: `${reportData.office_name}`}];
    const addressDetails = [{ A: `${reportData.office_address}`}];
    const finalData = [...title, ...officeDetails, ...addressDetails, ...MonthDetails, ...header, ...table];

    return finalData;
  }


  const createHeader = (startIndex = 2, tabViewIndex = 0) => {
    const dates = {};
    for(let i = 0; i < DATES_IN_MONTH; i++) {
      dates[keyArray[i + startIndex]] = daysArray[i];
    }

    if (tabViewIndex === 0) {
      dates[keyArray[DATES_IN_MONTH + startIndex]] = "Total";
    } else {
      dates[keyArray[DATES_IN_MONTH + startIndex]] = summaryData.label_wd;
      dates[keyArray[DATES_IN_MONTH + startIndex + 1]] = summaryData.label_wo;
      dates[keyArray[DATES_IN_MONTH + startIndex + 2]] = summaryData.label_ab;
      dates[keyArray[DATES_IN_MONTH + startIndex + 3]] = summaryData.label_totaldays;
    }

    return dates;
  }

  const createSummaryTab = () =>  {
    const days = createHeader(3, 1);
    let header = [{A: summaryData.label_header_1_1, B: summaryData.label_header_1_2, C: summaryData.label_header_1_3, ...days}];
    let nameTable = [];

    let MonthDetails = [
      {
        A: `${summaryData.label_report_month} ${summaryData.label_month} ${summaryData.label_year}`
      }
    ]

    let table = [];
   
    summaryData.attendance_summary_data.forEach((row, index) => {
      const studentDetails = row;

      let nameTable = [];
      let table1 = [];
      
      const in_time_obj = {};
      for(let i = 1; i<= daysArray.length; i++) {
        in_time_obj[keyArray[i + 2]] =  getSummaryDataByKey(studentDetails.dates, daysArray[i-1], "status", i-1);
      }

      const detailsObj = {};
      
      detailsObj[keyArray[DATES_IN_MONTH + 3]] = studentDetails.num_working_days;
      detailsObj[keyArray[DATES_IN_MONTH + 4]] = studentDetails.num_weekly_off;
      detailsObj[keyArray[DATES_IN_MONTH + 5]] = studentDetails.num_absent_days;
      detailsObj[keyArray[DATES_IN_MONTH + 6]] = studentDetails.total_working_days;
      
      table1.push({A: studentDetails.emp_name, B: studentDetails.emp_id, C: studentDetails.emp_jobrole, ...in_time_obj, ...detailsObj});
      table = table.concat(table1)
    });

    const title = [{ A: `${summaryData.le_name}`}, {}];
    const officeDetails = [{ A: `${reportData.office_name}`}];
    const addressDetails = [{ A: `${reportData.office_address}`}];
    const finalData = [...title, ...officeDetails, ...addressDetails, ...MonthDetails, ...header, ...table];

    return finalData;
  }


  const handleExport = () => {
    const title = [{ A:  summaryData.le_name}, {}];
    const reportTabData = createReportTab();
    const summaryTabData = createSummaryTab();
    //create a new workbook
    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(reportTabData, {
      skipHeader: true,
    });

    const summarySheet = XLSX.utils.json_to_sheet(summaryTabData, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "Attendance_Report");
    XLSX.utils.book_append_sheet(wb, summarySheet, "Attendance_Summary");

    // binary large object
    // Since blobs can store binary data, they can be used to store images or other multimedia files.

    const workbookBlob = workbook2blob(wb);

    return addStyle(workbookBlob);
  };

  const addStyle = (workbookBlob) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {

      let dataInfo = {
        titleCell: "A1",
        titleRange: `A1:AL2`,
        tbodyRange: `A5:AL2`
      };

      workbook.sheets().forEach((sheet, i) => {
        let finalCell = 0;
        if(i === 0) {
          finalCell = keyArray[DATES_IN_MONTH + (REPORT_PAGE_START_CELLS +  REPORT_PAGE_END_CELLS) - 1];

          dataInfo = {
            titleCell: "A1",
            titleRange: `A1:${finalCell}2`,
            tbodyRange: `A6:${finalCell}2`
          };

          for(let index = 0; index <= (REPORT_PAGE_START_CELLS - 1) ; index++) {
            sheet.column(keyArray[index]).width(WIDER_CELL_WIDTH + 2);
          }

          sheet.column(finalCell).width(WIDER_CELL_WIDTH);

          sheet.range("A6:B6").style({  //Data cell 
            horizontalAlignment: "left",
            verticalAlignment: "left",
            fontSize: 9
          });

          sheet.range("C4:AH4").style({  //Data cell 
            horizontalAlignment: "center",
            verticalAlignment: "center",
            fontSize: 9
          });

          for(let index = 0; index < reportData.attendance_data.length; index++ ) {
            
            let val_1 = 7 + 4 * index;

            sheet.range(`A${val_1}:A${val_1}`).style({   //Emp ID
              bold: true,
              horizontalAlignment: "left",
              verticalAlignment: "center",
              fontSize:8
            });

            sheet.range(`B${val_1}:B${val_1 + 3}`).merged(true).style({
              bold: true,
              horizontalAlignment: "left",
              verticalAlignment: "center",
              fontSize:8
            });

            sheet.range(`C${val_1}:AC${val_1}`).style({   //Emp ID
              bold: true,
              horizontalAlignment: "left",
              verticalAlignment: "center",
              fontSize:8
            });
  
            for(let dataIndex = 0 ; dataIndex < 3; dataIndex++) {
              const startIndex = 8 + (4 * index) + dataIndex; 

              sheet.range(`A${startIndex}:B${startIndex}`).style({  //Data cell
                horizontalAlignment: "left",
                verticalAlignment: "center",
                fontSize: 7.5
              });

              sheet.range(`C${startIndex}:AL${startIndex}`).style({  //Data cell
                horizontalAlignment: "center",
                verticalAlignment: "center",
                fontSize: 7.5
              });
            }
          }

          for(let index = REPORT_PAGE_START_CELLS; index <= (DATES_IN_MONTH + REPORT_PAGE_END_CELLS); index++) {
            sheet.column(keyArray[index]).width(EACH_CELL_WIDTH);
          }
        } else {
          finalCell = keyArray[DATES_IN_MONTH + (SUMMARY_PAGE_START_CELLS + SUMMARY_PAGE_END_CELLS) - 1];
          
          dataInfo = {
            titleCell: "A1",
            titleRange: `A1:${finalCell}2`,
            tbodyRange: `A5:${finalCell}460`
          };
          
          sheet.column("A").width(WIDER_CELL_WIDTH + 10);
          sheet.column("B").width(WIDER_CELL_WIDTH + 3);
          sheet.column("C").width(WIDER_CELL_WIDTH + 7);

          for(let inx = SUMMARY_PAGE_START_CELLS; inx <= (DATES_IN_MONTH + SUMMARY_PAGE_START_CELLS); inx++) {
            sheet.column(keyArray[inx]).width(2);
          }

          for(let index = SUMMARY_PAGE_START_CELLS + DATES_IN_MONTH; index <= (DATES_IN_MONTH + SUMMARY_PAGE_START_CELLS + SUMMARY_PAGE_END_CELLS - 2); index++) {
            sheet.column(keyArray[index]).width(3);
          }

          sheet.range(`A6:C${summaryData.attendance_summary_data.length + 7 - 1}`).style({
            horizontalAlignment: "left",
            verticalAlignment: "center",
            fontSize: 7.5
          });
        
          sheet.range("D5:AL250").style({
            horizontalAlignment: "center",
            verticalAlignment: "center",
            fontSize: 7.5
          });
        }

        sheet.range(dataInfo.titleRange).merged(true).style({
          bold: true,
          fontSize: 18,
          horizontalAlignment: "center",
          verticalAlignment: "center"
        });

        sheet.range(`A3:${finalCell}3`).merged(true).style({
          bold: true,
          fontSize: 14,
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        sheet.range(`A4:${finalCell}4`).merged(true).style({
          bold: true,
          fontSize: 14,
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        sheet.range(`A5:${finalCell}5`).merged(true).style({
          bold: true,
          fontSize: 14,
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        sheet.range(`C6:${finalCell}6`).style({
          fontSize: 8,
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
      });
  };

  const getReportDataByKey = (data, day, key, index = 0) => {
    const filterItem = data.filter((item, inx) => parseInt(item.date_of_month) === parseInt(day) && index === inx);

    return filterItem.length === 0 ? "-" : filterItem[0][key];
  }

  const getSummaryDataByKey = (data, day, key, index = 0) => {
    const filterItem = data.filter((item, inx) => parseInt(item.date_of_month) === parseInt(day)  && index === inx);

    return filterItem.length === 0 ? "-" : filterItem[0][key];
  }

  return (
    <button
      onClick={() => {
        createDownLoadData();
      }}
      className="btn btn-danger float-end"
    >
      Export
    </button>
  );
};

export default ExcelExportHelper;