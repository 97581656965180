import React, { useState, useEffect } from 'react';
import {useHistory, useParams, Link } from 'react-router-dom';
import {useSelector} from 'react-redux';
import CustomizedSnackbars from '../../components/CustomizedSnackbars';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Input from '../../components/Input';
import Loading from '../../components/loading/Loading';
import SelectView from '../../components/Select/SelectView';
import SwitchView from '../../components/switch/SwitchView';
import TextAreaView from '../../components/TextAreaView';
import TimePickerView from '../../components/TimePicker/TimePickerView';
import PayrollSelectView from '../../components/PayrollSelectView/PayrollSelectView';
import OfficeSelectView from '../../components/OfficeSelectView/OfficeSelectView';
import JobRoleSelectView from '../../components/JobRoleSelectView/JobRoleSelectView';
import RoleSelectView from '../../components/RoleSelectView/RoleSelectView';

import { 
    AttendanceType, 
    Designation, 
    Gender
} from '../../util/utils';

import {
    isTextValid, 
    isEmailValid, 
    isPhoneNumberValid
} from '../../util/DateTimeConverter';

import {InitialEmployee} from '../../util/DefaultInitialValues';
import ic_office_g from '../../assets/images/ic_office_g.png';

import {baseURL} from '../../actions/Constants';
import avatar from '../../assets/images/avatar.png'; 
import emp_icon from '../../assets/images/emp.png';
import circle_logo from '../../assets/images/circle.png';

import './employee.css';

const EditEmployee = (props) => {
    const { isOpen = false , le = "SMMC", details = {InitialEmployee}, onHandleCloseDialog, onUpdateSuccess} = props;

    const isEdit = details && details.emp_id !== "";
    const title = isEdit ? 'EDIT EMPLOYEE' : 'ADD EMPLOYEE';

    const [access_ofc_val, setAccessOfc] = useState("");
    const [baseOfcDetails, setBaseOfcDetails] = useState(null);

    const {
        details : payrollDetails
    } = useSelector(({ payroll: { details} }) => ({ details }));

    const {
        details : OfficesDetails
    } = useSelector(({ office: { details} }) => ({ details }));

     const {
      details:loggedAdminDetails
    } = useSelector(({ loggedAdmin: { details, loading, error } }) => ({ details, loading, error }));

    useEffect(() => {
        let obj = details;

        if(isEdit && details && typeof(details.emp_configuration) === "string" && details.emp_configuration !== "") {  
            obj = {...obj, emp_configuration: JSON.parse(details.emp_configuration)};
            //setEmployee({...details, emp_configuration: JSON.parse(emp_configuration)});
        }

        if(isEdit && details && typeof(details.emp_access_office_ids) === "string" && details.emp_access_office_ids !== "") {
            obj = {...obj, emp_access_office_ids: JSON.parse(details.emp_access_office_ids)};
        }

        setEmployee(obj);
    }, [details]);

    const initErrorState = {
        empIdError: false,
        empNameError: false,
        empGenderError: false,
        empPhoneError: false,
        empPayrollError: false,
        empRoleError: false,
        empJobRoleError: false,
        empAttendanceTypeError: false,
        empShiftInError: false,
        empShiftOutError: false,
        empBaseOfcError: false,
        empMgrIDError: false
    };

    const [errors, setErrors] = useState(initErrorState);
    const [emp, setEmployee] = useState(InitialEmployee);
    const [open, setOpen] = useState(isOpen);
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('md');

    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [isFormSubmit, setFormSubmit] = useState(false);
    const [isEmployeeExist, setEmployeeExist] = useState(false);
    const [isValidForm, setValidForm] = useState(true);

    const [newProfilePic, setNewProfilePic] = useState(null);
    
    useEffect(() => {
        document.title = `${isEdit ? 'Edit' : 'Add'} Employee - PC Chandra`;
    }, []);

    useEffect(() => {
        if (!isValidForm) {
            const firstInvalidEle = document.querySelector('[aria-invalid="true"]');
            if (firstInvalidEle) firstInvalidEle.focus();
            setValidForm(true);
        }
    }, [isValidForm]);


    const handleClickOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);
  

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") return;

        setOpen(false);
        onHandleCloseDialog();
    };

    const onInputChange = (e) => setEmployee({...emp, [e.target.name] : e.target.value});

    const validate = () => {
        let isFormValid = true;

        let isinValidEmpID = false; 
        let isinValidEmpName = false;
        let isinValidEmpGender = false;
        let isinValidPhone = false;
        let isinValidPayroll = false;
        let isinValidRole = false;
        let isinValidJobRole = false;
        let isinValidAttendanceType = false;

        let isinValidShiftInTime = false;
        let isinValidShiftOutTime = false;
        let isinvalidBaseOfcID = false;
        let isinValidMgrID = false;

        if(!isTextValid(emp_id)) {
            isFormValid = false;
            isinValidEmpID = true;
        }

        if(!isTextValid(emp_name)) {
            isFormValid = false;
            isinValidEmpName = true;
        }

        if(!isTextValid(emp_gender)) {
            isFormValid = false;
            isinValidEmpGender = true;
        }

        if(!isPhoneNumberValid(emp_phone_no)) {
            isFormValid = false;
            isinValidPhone = true;
        }

        if(!isTextValid(emp_gender)) {
            isFormValid = false;
            isinValidEmpGender = true;
        }

        if(emp_configuration && !isTextValid(emp_configuration.payroll)) {
            isFormValid = false;
            isinValidPayroll = true;
        }

        if(!isTextValid(emp_role)) {
            isFormValid = false;
            isinValidRole = true;
        }

        if(emp_configuration && !isTextValid(emp_configuration.jobrole)) {
            isFormValid = false;
            isinValidJobRole = true;
        }

        if(emp_configuration && !isTextValid(emp_configuration.attendance_method)) {
            isFormValid = false;
            isinValidAttendanceType = true;
        }


        if(emp_configuration && emp_configuration.shift_in_time == null) {
            isFormValid = false;
            isinValidShiftInTime = true;
        }

        if((emp_configuration && emp_configuration.shift_out_time) == null) {
            isFormValid = false;
            isinValidShiftOutTime = true;
        }

        if(!isTextValid(emp_base_office_id)) {
            isFormValid = false;
            isinvalidBaseOfcID = true;
        }

        if (!isTextValid(emp_manager_emp_id)) {
            isFormValid = false;
            isinValidMgrID = true;
        }

        setErrors({...errors,
            empIdError: isinValidEmpID,
            empNameError: isinValidEmpName,
            empGenderError: isinValidEmpGender,
            empPhoneError: isinValidPhone,
            empPayrollError: isinValidPayroll,
            empRoleError: isinValidRole,
            empJobRoleError: isinValidJobRole,
            empAttendanceTypeError: isinValidAttendanceType,
            empShiftInError: isinValidShiftInTime,
            empShiftOutError: isinValidShiftOutTime,
            empBaseOfcError: isinvalidBaseOfcID,
            empMgrIDError: isinValidMgrID
        });

        setValidForm(isFormValid);
        return isFormValid;
    }

    const updateConfigData = (e) => {
        setEmployee({...emp, emp_configuration: {...emp_configuration, [e.target.name]: e.target.value}});
    }

    const updateShiftTime = (key, value) => {
        setEmployee({...emp, emp_configuration: {...emp_configuration, [key] : value}});
    }

    const updateSwitchValue = (name, value) => {
        setEmployee({...emp, emp_configuration: {...emp_configuration, [name]: value}});
    }

    const UpdateEmpDetails = async () => {
        const URL= isEdit ? `${baseURL}/updateUser` : `${baseURL}/insertUser`;
        setLoading(true);

        if(legal_entity_id === "") {
            emp.legal_entity_id = le;
        }

        if(emp_access_office_ids !== "") {
            emp.emp_access_office_ids = JSON.stringify(emp_access_office_ids);
        }

        if(emp_configuration !== "") {
            emp.emp_configuration = JSON.stringify(emp_configuration);
        }

        const formattedPayroll = payrollDetails && payrollDetails[0] && JSON.parse(payrollDetails[0].legal_entity_configuration)
        const empRoleDetails = formattedPayroll.emp_role_details;

        const filteredRole = empRoleDetails.filter((empRole, index) => empRole.emp_role_name === emp_role);
        if(filteredRole.length > 0) {
            emp.emp_permission_level = filteredRole[0].emp_permission_level;
        }

        return await fetch(URL, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            body: JSON.stringify(emp), // body data type must match "Content-Type" header
            headers: {
              'Content-Type': 'application/json',
            }
        })
        .then((res) => {
            //Close Dialog
            setLoading(false);
            setError(false);
            onUpdateSuccess();
            onHandleCloseDialog();
        })
        .catch((error) => {
            console.log('error updateUser/insertUser', error);
            setLoading(false);
            setError(true);
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
       
        const isFormValid = validate();
        if (isFormValid) {
            if(newProfilePic) {
                uploadProfileImage();
            } else {
                UpdateEmpDetails();
            }
            setFormSubmit(true);
        }
    };

    const addToAccessList = (e) => {
        const {value} = e.target;
        const list = (emp_access_office_ids.access_list) ? emp_access_office_ids.access_list : [] ;

        if (list !== "") {
            const newList = list.filter((item, index) => item.office_id === value); 
            if(newList && newList.length === 0) {
                const selectOfc = OfficesDetails.filter((ofc, index) => ofc.office_id === value);
                let obj = {};
                const d = new Date();
                
                obj.office_id = value;
                obj.office_name = selectOfc[0].office_name;
                obj.office_address = selectOfc[0].office_address;
                obj.access_given_on = "" + d.getTime();
                obj.access_given_by = loggedAdminDetails.emp_id;
                list.push(obj);
            }
        }

        setEmployee({...emp, emp_access_office_ids: {...emp_access_office_ids, access_list: list}});
    }

    const removeFromAccessList = (ofc_id) => {
        const list = emp_access_office_ids.access_list;
        const newList = list.filter((item, index) => item.office_id !== ofc_id); 
        setEmployee({...emp, emp_access_office_ids: {...emp_access_office_ids, access_list: newList}});
    }

    const uploadProfile = (e) => {
        const newPic = e.target.files[0];
        setNewProfilePic(newPic);
        setEmployee({...emp, emp_profile_pic: URL.createObjectURL(newPic)});
    }

    const uploadProfileImage = async () => {
        const URL = `${baseURL}/uploadUserProfilePicToFirebase`;
        setLoading(true);

        const obj = {};
        obj.legal_entity_id = le;
        obj.emp_id = emp_id;

        var data = new FormData();
        data.append('body', JSON.stringify(obj));
        data.append("file", newProfilePic);

        return await fetch(URL, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            body: data // body data type must match "Content-Type" header 
        })
        .then(res =>  res.json())
        .then((res) => {
            const path = res.data;
            emp.emp_profile_pic = path;
            UpdateEmpDetails();
        })
        .catch((error) => {
            console.log('error updateUser/insertUser', error);
            setLoading(false);
        });
    }

    const {
        emp_id = "",
        emp_name = "",
        emp_email = "",
        emp_phone_no = "",
        emp_role = "",
        emp_designation = "",
        emp_gender = "",
        emp_manager_emp_id = "",
        legal_entity_id = "",
        emp_access_office_ids = "",
        emp_base_office_id = "",
        emp_access_level = "",
        emp_configuration = {
            auto_sign_in_out : false,
            gps_tracking_on : false,
            attendance_method : "",
            wfh_enabled : false,
            overtime_enabled : true,
            payroll : "",
            jobrole : "",
            shift_in_time : null,
            shift_out_time : null
        },
        emp_permission_level = "",
        emp_profile_pic = ""
    } = emp || {};

    useEffect(() =>{
        if(emp_base_office_id !== "") {
            const filterBaseOfc = OfficesDetails.filter((ofc, index) => ofc.office_id === emp_base_office_id);
            if(filterBaseOfc && filterBaseOfc[0]) {
                setBaseOfcDetails(filterBaseOfc[0]);
            }
        }
    },[emp_base_office_id]);
   
    return (
        <>
            {isLoading ? <Loading /> :
                (

                <Dialog 
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={isOpen}
                    onClose={handleClose}
                    aria-label=""
                    className="md"
                >
                    <div className="container-fluid p-5">
                        <div className="d-flex align-items-center px-lg-5 px-md-5 px-2">
                            <img src={circle_logo} className="circle_logo" />
                            <div className="logo_black ps-3"><span className="logo_red">One</span> Attendance</div> 
                        </div>

                        <div className="d-flex align-items-center justify-content-between px-lg-5 px-md-5 px-2">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <div> 
                                      <img src={emp_icon} className="pe-3" /> 
                                    </div>

                                    <div>
                                        <div className="dialog_h2_heading">Edit User Details</div>
                                        <div className="mt-3">
                                            <div className="dialogSubText">Please edit the information you want to</div>
                                            <div className="dialogSubText">update for this user,in the form below</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="circle_image_container">
                                    <img className="circular-image" src={emp_profile_pic !== "" ? emp_profile_pic : avatar} alt="" />
                                </div>

                                {isEdit && <form className="change_pic_container">
                                    <input id="upload" type="file" className="a11y" onChange={uploadProfile} />
                                    <label for="upload" className="upload-icon">
                                        <i className='bx bx-cloud-upload fs-4 '></i>
                                    </label>
                                </form>
                                }
                            </div>
                        </div>

                        <form  onSubmit={e=>handleSubmit(e)} autoComplete="off" noValidate className="px-lg-5 px-md-3 px-2 py-3">
                            <div className="row">
                                <div className="col-lg-6 py-2">
                                    <Input
                                        label="Employee ID"
                                        name="emp_id"
                                        value={emp_id}
                                        errorFieldName="Employee ID"
                                        isInvalid={errors.empIdError}
                                        isError={errors.empIdError}
                                        isRequired={true}
                                        onInputChange={(e) => onInputChange(e)}
                                        isDisabled={isEdit ? true : false}
                                    />
                                </div>

                                <div className="col-lg-6 py-2">
                                    <Input
                                        label="Name"
                                        name="emp_name"
                                        value={emp_name}
                                        errorFieldName="Name"
                                        isInvalid={errors.empNameError}
                                        isError={errors.empNameError}
                                        isRequired={true}
                                        onInputChange={(e) => onInputChange(e)} 
                                    />    
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 py-2">
                                    <SelectView
                                        label="Employee Gender"
                                        name="emp_gender"
                                        value={emp_gender}
                                        list = {Gender}
                                        errorFieldName="Gender"
                                        isInvalid={errors.empGenderError}
                                        isError={errors.empGenderError}
                                        isRequired={true}
                                        onInputChange={(e) => onInputChange(e)} 
                                    /> 
                                </div>

                                <div className="col-lg-6 py-2">
                                    <Input
                                        label="Mobile Number"
                                        name="emp_phone_no"
                                        value={emp_phone_no}
                                        errorFieldName="Mobile Number"
                                        isInvalid={errors.empPhoneError}
                                        isError={errors.empPhoneError}
                                        isRequired={true}
                                        onInputChange={(e) => onInputChange(e)} 
                                    />    
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 py-2">
                                    <Input
                                        label="Designation"
                                        name="emp_designation"
                                        value={emp_designation}
                                        onInputChange={(e) => onInputChange(e)} 
                                    />    
                                </div>
                                <div className="col-lg-6 py-2">
                                    <Input
                                        label="Email"
                                        name="emp_email"
                                        value={emp_email}
                                        onInputChange={(e) => onInputChange(e)} 
                                    />
                                </div>
                            </div>

                            <h5 className="px-2 pt-4">Role Details</h5>

                            <div className="row">
                                <div className="col-lg-6 py-2">
                                    <PayrollSelectView
                                        label="Payroll"
                                        name="payroll"
                                        value={emp_configuration.payroll}
                                        errorFieldName="Payroll"
                                        isInvalid={errors.empPayrollError}
                                        isError={errors.empPayrollError}
                                        isRequired={true}
                                        onInputChange={(e) => updateConfigData(e)}
                                        isStandard= {true}
                                    />
                                </div>

                                <div className="col-lg-6 py-2">
                                    <JobRoleSelectView
                                        label="Job Role"
                                        name="jobrole"
                                        value={emp_configuration.jobrole}
                                        errorFieldName="Job Role"
                                        isInvalid={errors.empJobRoleError}
                                        isError={errors.empJobRoleError}
                                        isRequired={true}
                                        onInputChange={(e) => updateConfigData(e)} 
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12 py-2">
                                    <RoleSelectView
                                        label="Access Level"
                                        name="emp_role"
                                        value={emp_role}
                                        errorFieldName="Role"
                                        isInvalid={errors.empRoleError}
                                        isError={errors.empRoleError}
                                        isRequired={true}
                                        onInputChange={(e) => onInputChange(e)} 
                                    />

                                </div>
                            </div>

                            <h5 className="px-2 pt-4">Configuration</h5>
                            <div className="row">
                                <div className="col-lg-6 py-2">
                                    <SelectView
                                        label="Attendance Method"
                                        name="attendance_method"
                                        value={emp_configuration.attendance_method}
                                        list={AttendanceType}
                                        errorFieldName="Attendacne Type"
                                        isInvalid={errors.empAttendanceTypeError}
                                        isError={errors.empAttendanceTypeError}
                                        isRequired={true}
                                        onInputChange={(e) => updateConfigData(e)} 
                                    />
                                </div>

                                <div className="col-lg-6 py-3">
                                    <SwitchView 
                                        label ="Over Time" 
                                        name="overtime_enabled" 
                                        value={emp_configuration.overtime_enabled} 
                                        updateSwitchValue={(name, val) => updateSwitchValue(name, val)} 
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 py-3">
                                    <SwitchView 
                                        label ="GPS Tracking" 
                                        name="gps_tracking_on" 
                                        value={emp_configuration.gps_tracking_on} 
                                        updateSwitchValue={(name, val) => updateSwitchValue(name, val)} 
                                    />
                                </div>
                                <div className="col-lg-6 py-3">
                                    <SwitchView 
                                        label="Work From Home"
                                        name="wfh_enabled" 
                                        value={emp_configuration.wfh_enabled} 
                                        updateSwitchValue={(name, val) => updateSwitchValue(name, val)} 
                                    />
                                </div>
                            </div>

                            <h5 className="px-2 pt-4">Office Details</h5>
                            <div className="row">
                                <div className="col-lg-6 py-3">
                                    <label className="field pb-2">Shift In Time *</label>
                                    <TimePickerView 
                                        label ="Shift In" 
                                        name="shift_in_time" 
                                        value={emp_configuration.shift_in_time}
                                        errorFieldName="Shift In"
                                        isInvalid={errors.empShiftInError}
                                        isError={errors.empShiftInError}
                                        isRequired={true}
                                        setUpdateTime={(val) => updateShiftTime("shift_in_time", val)}
                                    />
                                </div>
                                <div className="col-lg-6 py-3">
                                    <label className="field pb-2">Shift Out Time *</label>
                                    <TimePickerView
                                        label="Shift Out"
                                        name="shift_out_time" 
                                        value={emp_configuration.shift_out_time}
                                        errorFieldName="Shift Out"
                                        isInvalid={errors.empShiftOutError}
                                        isError={errors.empShiftOutError}
                                        isRequired={true}
                                        setUpdateTime={(val) => updateShiftTime("shift_out_time", val)}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 py-2">
                                    <OfficeSelectView
                                        label="Base Office"
                                        name="emp_base_office_id"
                                        value={emp_base_office_id}
                                        isRequired={true}
                                        errorFieldName="Base Office"
                                        isInvalid={errors.empBaseOfcError}
                                        isError={errors.empBaseOfcError}
                                        isRequired={true}
                                        onInputChange={(e) => onInputChange(e)} 
                                    />
                                </div>

                                <div className="col-lg-6 py-2">
                                    <Input
                                        label="Employee Manager ID"
                                        name="emp_manager_emp_id"
                                        value={emp_manager_emp_id}
                                        errorFieldName="Manager ID"
                                        isInvalid={errors.empMgrIDError}
                                        isError={errors.empMgrIDError}
                                        isRequired={true}
                                        onInputChange={(e) => onInputChange(e)} 
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12 py-2">
                                    <OfficeSelectView
                                        label="Employee Access List"
                                        name="emp_access_office_list"
                                        value={access_ofc_val}
                                        isRequired={true}
                                        onInputChange={(e) => addToAccessList(e)} 
                                    />
                                </div> 
                            </div>

                            <div className="row">
                                <div className="col-lg-12 py-2">

                                    <div className="my-2 rounded d-flex justify-content-between access_list py-3 px-lg-5 px-2 align-items-center">
                                        <div className="d-flex align-items-center">
                                            <img src={ic_office_g} className="ofc_icon pe-2" />
                                            <div>
                                                <div>{baseOfcDetails && baseOfcDetails.office_id || ""}</div>
                                                <div>{baseOfcDetails && baseOfcDetails.office_address || ""}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {Array.isArray(emp_access_office_ids.access_list) && emp_access_office_ids.access_list.map((ofc, index) => {
                                        return (
                                            <div key={ofc.office_id} className="my-2 rounded d-flex justify-content-between access_list py-3 px-lg-5 px-2 align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <img src={ic_office_g} className="ofc_icon pe-2" />
                                                    <div>
                                                    <div>{ofc.office_id}</div>
                                                    <div>{ofc.office_address}</div>
                                                    </div>
                                                </div>
                                                <i className='bx bxs-message-alt-x fs-5' onClick={() => removeFromAccessList(ofc.office_id)}></i>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                            <div className="d-flex pt-3 px-2 justify-content-end">
                                <button type="button" className="btn btn-outline-danger mt-3" onClick={handleClose}>Cancel</button>
                                <button type="submit" className="btn btn-danger mt-3 ms-3">{isEdit ? 'Update' : 'ADD'}</button>
                            </div>
                        </form>
                    </div>
                </Dialog>)
            }
            {isError && <CustomizedSnackbars showAlert = {(val) => setError(false)} />}
        </>
    )
};

export default EditEmployee;