import React, {useEffect} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Blank from './pages/Blank'
import Dashboard from './pages/Dashboard';
import MainLayout from './layout/MainLayout';
import Report from './pages/Report';
import Login from './pages/Login/Login';
import Logout from './pages/Logout/Logout';
import Offices from './pages/Office/Offices';
import EmployeesList from './pages/Employes/EmployeesList';
import EditEmployee from './pages/Employes/EditEmployee';
import ConfigureDevice from './pages/ConfigureDevice/ConfigureDevice';
import Settings from './pages/ConfigureDevice/Settings';
import Roster from './pages/Roster/Roster';
import Protected from "./Protected";

import './scss/App.scss'

function App() {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: "smooth"});
  }, []);

  return (
   <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/configure-device" element={<Protected><ConfigureDevice /></Protected>} />
        <Route exact path="/" element={<Protected><MainLayout /></Protected>}>
          <Route index element={<Dashboard />} />
          <Route path="report" element={<Report />} />
          <Route path="employes" element={<EmployeesList />} />
          <Route path="offices" element={<Offices />} />
          <Route path="roster" element={<Roster />} />
          <Route path="/settings" element={<Settings />} />
          <Route exact path='/employee/add' element={<EditEmployee />} />
          <Route path='/employee/edit/:id' element={<EditEmployee />} />
          <Route exact path='/logout' element={<Logout />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
