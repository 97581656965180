import React from 'react';
import {useSelector} from 'react-redux';
import avatar from '../../assets/images/avatar.png'; 
import './user-info.scss'

const UserInfo = ({ user }) => {
    const {
        emp_profile_pic = ""
    } = user || {};

    const {
        details
    } = useSelector(({ filter: { details} }) => ({ details }));

    const {emp_legal_entity_name = ""} = details || {};

    return (
        <div className='user-info'>
            <div className="circle_image_container">
                <img className="circular-image" src={emp_profile_pic !== "" ? emp_profile_pic : avatar} alt="" />
            </div>

            <div className="user-info__name">
                <span className="inter_medium font_16">{user?.emp_name || "Guest"}</span>
                <div className="inter_light font_13">{user?.emp_role || "-"}</div>
                <div className="inter_light font_13 user-info__name__entity">{emp_legal_entity_name}</div>
            </div>
        </div>
    )
}

export default UserInfo
