import moment from 'moment';

const getTodayDate = () => {
  const d = new Date();
  let month = addZeroBefore(d.getMonth() + 1);
  let year = d.getFullYear();
  addZeroBefore(month);
  const dateVal = `${year}-${month}`;
  return dateVal;
}

const getMonthFirstDate = () => {
  const d = new Date();
  d.setDate(1);
  return d;
}

const getTimeStampsFromDate = (date = new Date()) => {
  const newDate = new Date(date);
  newDate.setHours(0,0,0,0);
  const startDateEpoc = newDate.getTime();
  return startDateEpoc;
}

const getEndDateEpoc = (date = new Date()) => {
  const d = new Date(date);
  d.setHours(23, 59, 59, 0);
  return d.getTime();
}

const convertToDate = (dateVal) => { //dateVal = DD-MM-YYYY
	const formatted = reverseDate(dateVal);
    const date = new Date(formatted);
    return date;
}

const getDateFromTimeStamp = (epoc) => { //miilisecond
	const dateVal = new Date(epoc * 1);
    let dd = dateVal.getDate();
    let mm = dateVal.getMonth() + 1;
    let yy = dateVal.getFullYear();
    if(dd< 10) {
      dd = '0' + dd;
    }
    if(mm< 10) {
      mm = '0' + mm;
    }

    const fullDate = dd + '-' + mm + '-' + yy;

    return fullDate;
}

function msToTime(duration) {
  if (!duration || duration === '-') return '-';
  var milliseconds = Math.floor((duration % 1000) / 100),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  return hours + ":" + minutes;
}

const getLocationsFromStr = (val) => {
	if(val === '') return null;
    const locstr = val.split("[");
    const locstr2 = locstr[1].split(" ");
    const latlong = locstr2[1].split(",");
    return {lat: latlong[0], long: latlong[1]};
}

const reverseDate = (dateVal) => {
	return dateVal.split('-').reverse().join("-");
}

const extractDateFromMoment = (date) => {
	const currentDateValue =  moment(date).startOf('day').format('DD-MM-YYYY');
	return currentDateValue; //in DD-MM-YYYY format
}

const getMonthBeforeDate = (dateVal) => {
	const formattedDate = reverseDate(dateVal);
	return moment(formattedDate).add(-30, 'days').format('DD-MM-YYYY');
}

const getTimeOnFloor = (startTime, secsVal) => {
	if (secsVal === '0' && startTime === '0') return "-";
	let tofa = parseInt(secsVal);
    let finalVal = '';
    let hrs = parseInt(tofa/3600);
    let mins = parseInt((tofa - (3600*hrs))/60);
    if (hrs > 24) hrs = 24;
    if (mins > 60) mins = 60;

    if (hrs > 0) finalVal = `${hrs}h `;
    if (mins > 0) finalVal += `${mins}m`;

	return finalVal;
}

const addZeroBefore = (n) => {
  return (n < 10 ? '0' : '') + n;
}

const isTextValid = (val) => {
	return  (val && val !== '' && val !== '-');
}

const isPhoneNumberValid = (no) => {
	const regex = /^[6-9][0-9]{9}$/;
    return regex.test(no);
}

const isEmailValid = (email) => {
  const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

const getYears = () => {
	const years = [];
	for (var i = 2000; i< 2100; i++) {
		years.push(i);
	}

	return years;
}

const getMonths = () => ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const getDaysInMonth = (month,year) => new Date(year, month, 0).getDate();

const generateLabels = (totalDays) => {
	let labels = [];
	for (var i = 1; i<= totalDays; i++) {
      labels.push(addZeroBefore(i));
    }
    return labels; 
}

const getRandomInt = (min, max) => {
	return Math.floor(Math.random() * (max - min + 1) + min);
}

/*Dummy*/
const createDataSet = (totalDays) => {
	const dummyDataSet =  [4.1, 3, 0, 8.7, 8.3, 3.9, 0, 7.5, 3.2, 0, 8.1, 0, 4, 4.7, 0, 7.8, 7.5, 7.4, 7.3, 0, 4.3, 4.5, 7.2, 7.1, 7.5, 8, 7.9, 7.5, 0, 7.4, 7.2, 0, 7.8, 7.7, 0, 7.5, 7.2, 7.3, 0, 0, 7.4, 7.1, 0, 8, 7.7, 8.5, 0, 6.8, 8, 7, 0, 8.5, 9.7, 6.4, 7.2, 6.8, 9, 5.4, 0, 7.5, 4.5];
    let dataset = [];
    for (var i = 0; i< totalDays; i++) {
      const val = getRandomInt(0, 50);
      dataset.push(dummyDataSet[val]);
    }
    return dataset;
};

const getTotalLeaves = (data) => {
	let leaves = 0;
	for (var i = 0 ; i < data.length; i++) {
		if (data[i] === 0) leaves += 1;
		else if (data[i] > 0 && data[i] <= 4.5) {
			leaves += 0.5;
		}
	}
	return leaves;
}

const getAvgEffort = (data, workingDays) => {
	let efforts = 0;
	for (var i = 0 ; i < data.length; i++) {
		efforts += data[i];
	}
	if (workingDays === 0) return efforts;
	else return efforts/workingDays;
}

// Date utilities
export { 
  addZeroBefore, 
  convertToDate, 
  createDataSet, 
  extractDateFromMoment, 
  generateLabels, 
  getAvgEffort, 
  getDaysInMonth, 
  getEndDateEpoc, 
  getLocationsFromStr, 
  getMonthBeforeDate, 
  getMonthFirstDate, 
  getRandomInt, 
  getTimeStampsFromDate, 
  getTodayDate, 
  getDateFromTimeStamp, 
  reverseDate 
};

// Leave and time tracking
export { 
  getMonths, 
  getTotalLeaves, 
  getYears 
};

// Validation utilities
export { 
  isEmailValid, 
  isPhoneNumberValid, 
  isTextValid 
};

// Time conversion
export { 
  msToTime 
};
