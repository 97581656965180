import React, {useState, useEffect} from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import {useSelector} from 'react-redux';
import {PCC_OneAttendance_version} from './actions/Constants';
import {UserPermissionDetails} from './util/DefaultInitialValues';

const Protected = ({children}) => {

	const location = useLocation();

  	const {
    	details,
    	loading,
    	error
  	} = useSelector(({ loggedAdmin: { details, loading, error } }) => ({ details, loading, error }));

  	const [currentLocation, setLocation] = useState("");

  	useEffect(() => {
      let locationVal = location?.pathname;
      setLocation(locationVal);
    }, [location]);


    const validatePath = () => {
      const userRole = details?.emp_role?.toLowerCase();
      const filteredUserRoleDetails = UserPermissionDetails.filter(ePermission => ePermission?.role === userRole);
      if(filteredUserRoleDetails && filteredUserRoleDetails?.[0]) {
        const item = filteredUserRoleDetails?.[0];

        if(item?.pages?.includes("*") ||item?.pages?.includes(currentLocation)) {
          return true;
        }
        return false;
      }

      return false;
    }


    /*const validatePath = () => {
      const userRole = details?.emp_role?.toLowerCase();
      
      const adminOrManager = userRole && (userRole.includes('admin') || userRole.includes('manager'));
      const isAdministrator = userRole === "administrator";

      if (currentLocation === "" || currentLocation === "/" || currentLocation === "/configure-device" || currentLocation === "/employes" || currentLocation === "/offices" ) {
        return adminOrManager;
      } 

      if (currentLocation === "/report") {
        return adminOrManager;
      } 

      if (currentLocation === "/roster") {
        return adminOrManager; // Check for admin only
      }

      if (currentLocation === "/settings") {
        return isAdministrator; // Check for admin only
      } 

      // Fallback for other paths
      return isAdministrator;
    };
    */

  const redirectPath = () => {
    if (!isSameVersion) return "/login";
    const userRole = details?.emp_role?.toLowerCase();
    const isAdminOrManager = userRole && (userRole.includes('admin') || userRole.includes('manager'));

    return isAdminOrManager ? "/" : "/login";
  };

  const app_version = localStorage.getItem("PCC_OneAttendance_version");
  const isSameVersion = (app_version && app_version === PCC_OneAttendance_version);

  const isUserPresent = (validatePath() && isSameVersion) ? true : false;

	return isUserPresent ? children : <Navigate to = {redirectPath()} replace />;
};

export default Protected;