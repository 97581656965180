import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {InitialFilters} from '../../util/DefaultInitialValues';
import POSSelectView from '../../components/POSSelectView/POSSelectView';
import PayrollSelectView from '../../components/PayrollSelectView/PayrollSelectView';
import {saveFilters} from '../../actions/filters.actions.js';
import TabView from '../../components/tabView/TabView';
import {getConfigurationByLE} from '../../actions/filters.actions.js';
import PayrollConfigure from './PayrollConfigure';
import JobRoleConfigure from './JobRoleConfigure';
import EmpRoleConfigure from './EmpRoleConfigure';
import ShiftConfigure from './ShiftConfigure';
import LESelectView from '../../components/LESelectView/LESelectView';

import config_settings from '../../assets/images/Config_settings.png';

import { SelectPicker } from 'rsuite';
import {isEmptyJson} from '../../util/utils';

import {
  Legalentity_super_admin,
  Legalentity_Admin,
  Legalentity_Apex,
  Legalentity_Goldlites,
  Legalentity_Jewellers,
  Legalentity_Sons
} from '../../util/utils.js';

const Settings = () => {
  const dispatch = useDispatch();
  const titleVal = "Settings - OneAttendance";
	const hrefVal = window.location.href;
	const navigate = useNavigate();
  const [filters, setFilters] = useState(InitialFilters);
  const saveFilterDataAction = saveFilters;
  const [isFormValid, setFormValid] = useState(true);

  const [shiftData, setShiftData] = useState([]);
  const [payrollData, setPayrollData] = useState([]);
  const [jobRoleData, setJobRoleData] = useState([]);
  const [empRoleData, setEmpRoleData] = useState([]);

  const {
    details
  } = useSelector(({ filter: { details, loading, error } }) => ({ details, loading, error }));

  const {
    details: payrollDetails
  } = useSelector(({ payroll: { details} }) => ({ details }));

  const {
    office_id = "",
    emp_legal_entity_id = "",
    emp_id_pattern = "",
    date = ""
  } = filters;

	const handleSubmit = (e) => {
		e.preventDefault();
    const isFormValid = validate();

    if(isFormValid) {
      dispatch(saveFilterDataAction(filters));
      navigate("/");
    }
	}
  const validate = () => {
    let isFormValid = true;
    let isInvalidPOSIDError = false;
    let isInvalidLEError = false;

    if(emp_legal_entity_id === "") {
      isInvalidLEError = true;
      isFormValid = false;
    }

    setFormValid(isFormValid);
    return isFormValid;
  }

  const checkState = () => {
    if(emp_id_pattern === "" && office_id !== "") {
      return true;
    } else if (emp_id_pattern !== "" && office_id === "") {
      return true;
    } else {
      return false;
    }
  }

  const handleLEChange = (value) => {
    setFilters({...filters, ["emp_legal_entity_id"]: value, office_id: "", emp_id_pattern: ""});
  }

  const updateSelectValue = (name, value) => {
    setFilters({...filters, [name]: value});
  }

  useEffect(() => {
    setFilters(details);
  }, [details]);

  useEffect(() => {
    if(filters && filters.emp_legal_entity_id != "")
    dispatch(getConfigurationByLE(emp_legal_entity_id));
  }, [filters]);

  useEffect(() => {
    if(payrollDetails && !isEmptyJson(payrollDetails)) {
      const config_details = JSON.parse(payrollDetails[0].legal_entity_configuration);

      if(config_details && Array.isArray(config_details.shift_times)) {
        setShiftData(config_details.shift_times);
      }
      if(config_details && Array.isArray(config_details.emp_payroll_catagory)) {
        setPayrollData(config_details.emp_payroll_catagory);
      }
      if(config_details && Array.isArray(config_details.job_role_details)) {
        setJobRoleData(config_details.job_role_details);
      }
      if(config_details && Array.isArray(config_details.emp_role_details)) {
        setEmpRoleData(config_details.emp_role_details);
      }
    }
  }, [payrollDetails]);

  const ShiftConfigureTab = () => {
    return (
      <ShiftConfigure data = {shiftData} />
    );
  }

  const PayrollConfigureTab = () => {
    return (
      <PayrollConfigure data = {payrollData} />
    );
  }

  const JobRoleConfigureTab = () => {
    return (
      <JobRoleConfigure data = {jobRoleData} />
    );
  }

  const EmpRoleConfigureTab = () => {
    return (
      <EmpRoleConfigure data = {empRoleData} />
    );
  }

  const TabListData = [ShiftConfigureTab, PayrollConfigureTab, JobRoleConfigureTab, EmpRoleConfigureTab];
  const TabsData = ["Shift", "Payroll", "Job Role", "Emp Role"];

	return (
	  <>
    	<Helmet>
      	<title>{titleVal}</title>
      	<meta name="description" content="Login meta"></meta>
      	<link rel="canonical" href={hrefVal} />
    	</Helmet>

      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex align-items-center">
          <div> 
            <img src={config_settings} className="page_logo" /> 
          </div>

          <div>
            <div className="page_h1_heading">Configuration</div>
            <div className="page_le_text">{filters && filters?.emp_legal_entity_id}</div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="align-items-center col-lg-12 m-auto">
            <TabView 
              TabsData = {TabsData} 
              TabListData = {TabListData}
            />
          </div>
        </div>
      </div>
      {/*
      <div className="d-flex justify-content-end">
        <button className="btn btn-outline-danger ms-3" onClick={handleSubmit} disabled={checkState()}>Save</button>
      </div>
      */}

     </>
	)
}

export default Settings;