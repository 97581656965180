import React, {useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { removeAdmin, removeLoggedAdminRoles } from '../../actions/admin.actions';
import {useDispatch} from 'react-redux';

const Logout = () => {
  //other logic
  const navigate = useNavigate();
  const removeAdminAction = removeAdmin;
  const removeLoggedAdminRolesAction = removeLoggedAdminRoles;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(removeAdminAction());
    dispatch(removeLoggedAdminRolesAction());
    localStorage.removeItem("PCC_OneAttendance_admin");
    localStorage.removeItem("PCC_OneAttendance_version");
    
    navigate("/login");
  }, []);

  return (
    <>
    </>

  );
};

export default Logout;