import {
  SAVE_LOGGED_ADMIN_ROLES,
  REMOVE_LOGGED_ADMIN_ROLES
} from '../../actions/Constants';
   
const defaultState = {
  details: {},  //[]
  error: null,
  loading: false
}; 
 
export default function loggedUserAllRolesReducer(state = defaultState, action) {
  switch (action.type) {
    /*case GET_LOGGED_ADMIN_ROLES_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_LOGGED_ADMIN_ROLES_SUCCESS:
      return {
        ...state,
        details: action.payload,
        error: null,
        loading: false
      };
    case GET_LOGGED_ADMIN_ROLES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };*/
    case SAVE_LOGGED_ADMIN_ROLES:
      return {
        ...state,
        details: action.payload,
        error: action.error,
        loading: false
      };
    case REMOVE_LOGGED_ADMIN_ROLES:
      return {
        ...state,
        details: action.payload,
        error: action.error,
        loading: false
      };
    default:
      return state;
  }
}