import {
  GET_OFFICE_START,
  GET_OFFICE_SUCCESS,
  GET_OFFICE_FAILURE,
  baseURL
} from './Constants';

export const getAllOfficesByLegalEntity = le => async (dispatch) => { //Payroll
  if(!le) return;

  dispatch({
    type: GET_OFFICE_START,
    error: null
  });

  let obj = {};
  obj.legal_entity_id = le;
  
  const URL=`${baseURL}/getAllOfficesByLegalEntity`;
  
  return await fetch(URL, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    body: JSON.stringify(obj), // body data type must match "Content-Type" header
  })
  .then(res => res.json())
  .then((res) => {
    dispatch({
      type: GET_OFFICE_SUCCESS,
      payload: res,
      error: null
    });
  })
  .catch((error) => {
    console.log('error getAllOfficesByLegalEntity', error);
    dispatch({
      type: GET_OFFICE_FAILURE,
      error: error,
    });
  });
}

export const getOfficesFromOfficeID = office_id => async (dispatch) => { //Payroll
  if(!office_id) return;

  dispatch({
    type: GET_OFFICE_START,
    error: null
  });

  let obj = {};
  obj.office_id = office_id;
  
  const URL = `${baseURL}/getOfficeFromOfficeID`;
  
  return await fetch(URL, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    body: JSON.stringify(obj), // body data type must match "Content-Type" header
  })
  .then(res => res.json())
  .then((res) => {
    dispatch({
      type: GET_OFFICE_SUCCESS,
      payload: res.data,
      error: null
    });
  })
  .catch((error) => {
    console.log('error getOfficeFromOfficeID', error);
    dispatch({
      type: GET_OFFICE_FAILURE,
      error: error,
    });
  });
}





