import {getTodayDate, getMonthFirstDate} from './DateTimeConverter';

const allPages = ["", "/", "/configure-device", "/employes", "/offices", "/settings"];

const permissionAccess = {
    legal_entity_access_control: [
        {
            role: "Administrator",
            web_application_access:true,
            web_application: {
                page_access: ["home","user","report","shift","settings"],
                office_data_access: "ALL"
            },
            mobile_application: {
                update_profile:true,
                update_shift:true
            }
        },
        {
            role: "Sub-Administrator",
            web_application_access:true,
            web_application: {
                page_access: ["report"],
                office_data_access:"ALL"
            },
            mobile_application:{
                update_profile:true,
                update_shift:true
            }
        },
        {
            role:"Manager",
            web_application: {
                web_application_access:true,
                page_access:["report"],
                office_data_access:"BASE_OFFICE"
            },
            mobile_application:{
                update_profile:true,
                update_shift:true
            }
        },
        {
            role:"General",
            web_application: {
                web_application_access:false,
                page_access:[],
                office_data_access:"BASE_OFFICE"
            },
            mobile_application: {
                update_profile:false,
                update_shift:false
            }
        }
    ]
}

const UserPermissionDetails = [
    {
        role: "administrator",
        permission: "all",
        pages: [
            "*"
        ]
    },
    {
       role: "sub-administrator", 
       permission: "",
       pages: [
        "", "/", "/configure-device", "/report"
       ]
    },
    {
       role: "manager", 
       permission: "",
       pages: [
            "*"
        ]
    }
]

const InitialEmployee = {
    emp_id : "",
    emp_name : "",
    emp_email : "",
    emp_phone_no : "",
    emp_role : "",
    emp_designation : "",
    emp_gender : "",
    emp_manager_emp_id : "",
    legal_entity_id : "",
    emp_access_office_ids : {
        access_list : []
    },
    emp_base_office_id : "",
    emp_configuration : {
        auto_sign_in_out: false,
        gps_tracking_on: false,
        attendance_method: "None",
        wfh_enabled: false,
        overtime_enabled: true,
        payroll:"",
        jobrole: "",
        shift_in_time: null,
        shift_out_time: null
    },
    emp_permission_level : "",
    emp_profile_pic : ""
};

const InitialBeacon = {
	beacon_uuid: '',
    beacon_physical_id: '',
    showroom_id: '',
    showroom_name: '',
    beacon_role: 'Entry',
    showroom_base_lat: '',
    showroom_base_long: '',
    provisioning_date: '-',
    last_service: '-',
    threashold_value: '',
};

const InitialAttendance = {
	emp_id: '',
    date_of_attendance: '',
    leave_status: '',
    first_signin_time: '',
    last_signout_time: '',
    approver_emp_id: '',
    gps_trail: '-',
    signin_showroom_id: '-',
    signout_showroom_id: '-'
};


const InititalShiftData = {
    shift_id : "",
    shift_name : "",
    shift_in_time : null,
    shift_out_time : null
}

const InititalPayrollData = {
    payroll_id : "",
    name: "",
    description: "",
    emp_id_pattern: "",
    monthly_off_days: ""
}

const InititalJobRoleData = {
    job_role_id : "",
    job_role_name: ""
}

const InititalEmpRoleData = {
    emp_role_id : "",
    emp_role_name: ""
}

const InitialOffice = {
    office_id: "",
    office_phone: "",
    legal_entity_id: "",
    office_name: "",
    office_geofencing_conf: {
        lat : 0,
        lng: 0,
        radius: "300",
        address: "",
        city: "",
        state: "",
        country: "",
        postalCode: ""
    },

    office_address: "",
    office_configuration: {
        office_off_day: "",
        attendance_method: ""
    },
    office_beacon_conf: [],
    office_wifi_conf: [
    {
        bssid:"d0:72:dc:ea:09:e0",
        ssid:"$t@yw!f!",
        capabilities:"[WPA2-EAP/SHA1-CCMP][RSN-EAP/SHA1-CCMP][ESS]",
        signal_strength:"-68",
        enabled: false,
        threshold:"Anywhere"
    }
    ],
    office_email: "",
    office_description: ""
}

const InitialFilters = {
    office_id: "all", /*POS*/
    emp_legal_entity_id: "",
    emp_id_pattern: "all", /*Payroll*/
    startDate: getMonthFirstDate(),
    endDate: new Date()
};


const SampleReportData = {
    "sheet_data": [
        {
            "label_header_1_1": "Emp ID",
            "label_header_1_2": "Branch",
            "attendance_data": [
                {
                    "emp_jobrole": "ERPTeam",
                    "label_ot_hrs": "OT Hrs.",
                    "emp_branch": "SHYAMOLI",
                    "shift_in_time": "11:00",
                    "emp_name": "Saurav Majumder",
                    "shift_out_time": "19:00",
                    "dates": [
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "05:10",
                            "date_of_month": "1",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "01:20",
                            "date_of_month": "2",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "3",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "4",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "5",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "6",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "7",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "8",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "9",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "10",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "11",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "12",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "13",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "14",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "15",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "16",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "17",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "18",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "19",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "20",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "-",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "-",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "-",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "-",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "-",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "-",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "-",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "-",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "--:--",
                            "date_of_month": "-",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        },
                        {
                            "out_time": "--:--",
                            "manual_approve_status": false,
                            "in_time": "8",
                            "date_of_month": "1",
                            "ot_hrs": "0.00",
                            "manual_in_time": "--:--",
                            "manual_out_time": "--:--"
                        }

                    ],
                    "emp_designation": "HOD",
                    "label_intime": "In Time",
                    "total_ot_hrs": "00.00",
                    "label_outtime": "Out Time",
                    "emp_id": "HOD"
                }
            ],
            "oa_logo_url": "www",
            "label_report_month": "Monthly Attendance Report For: ",
            "office_name": "SHYAMOLI",
            "office_address": "P26-A  NANI GOPAL ROY CHOWDHURY AVENUE KOLKATA-700014",
            "le_logo_url": "www",
            "label_year": 2024,
            "le_name": "PCC Shyamoli IT",
            "sheet_name": "Attendance_Report",
            "label_month": "Nov",
            "label_dates": "30"
        },
        {
            "label_wd": "W/D",
            "attendance_summary_data": [
                {
                    "emp_jobrole": "ERPTeam",
                    "emp_branch": "SHYAMOLI",
                    "emp_name": "Saurav Majumder",
                    "num_absent_days": 1,
                    "num_working_days": 0,
                    "dates": [
                        {
                            "manual_approve_status": false,
                            "date_of_month": "1",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "2",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "3",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "4",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "5",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "6",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "7",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "8",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "9",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "10",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "11",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "12",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "13",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "14",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "15",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "16",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "17",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "18",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "19",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "20",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "21",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "22",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "23",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "24",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "25",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "26",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "27",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "28",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "29",
                            "status": " "
                        },
                        {
                            "manual_approve_status": false,
                            "date_of_month": "1",
                            "status": "P"
                        },
                    ],
                    "num_weekly_off": 6,
                    "emp_id": "HOD",
                    "total_working_days": 30
                }
            ],
            "label_totaldays": "Total Days",
            "label_header_1_1": "Emp Name",
            "label_header_1_2": "Emp ID",
            "label_header_1_3": "Job Role",
            "oa_logo_url": "www",
            "label_report_month": "Monthly Attendance Summary For: ",
            "label_ab": "AB",
            "le_logo_url": "www",
            "label_year": 2024,
            "le_name": "PCC Shyamoli IT",
            "label_wo": "W/O",
            "sheet_name": "Attendance_Summary",
            "label_month": "Nov",
            "label_dates": "1...31"
        }
    ]
}

export { 
    InitialEmployee, 
    InitialBeacon , 
    InitialOffice, 
    InitialAttendance, 
    InitialFilters, 
    InititalPayrollData, 
    InititalShiftData, 
    InititalJobRoleData, 
    InititalEmpRoleData, 
    UserPermissionDetails,
    SampleReportData
};
