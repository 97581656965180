import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Loading from '../../components/loading/Loading';
import {InitialFilters} from '../../util/DefaultInitialValues';

import {baseURL} from '../../actions/Constants';
import LESelectView from '../../components/LESelectView/LESelectView';
import {saveFilters} from '../../actions/filters.actions.js';
import {saveAdmin} from '../../actions/admin.actions.js';

import {getConfigurationByLE} from '../../actions/filters.actions.js';
import {getAllOfficesByLegalEntity, getOfficesFromOfficeID} from '../../actions/offices.actions.js';
import {isManager} from '../../util/utils';
import wave from '../../assets/wave.png';
import ofc_icon from '../../assets/images/ofc.png';
import pcc_logo from '../../assets/ic_logo_oneattendance5.png';

const ConfigureDevice = () => {
  const dispatch = useDispatch();
  const titleVal = "Configure device - oneAttendance";
	const hrefVal = window.location.href;
	const navigate = useNavigate();

  const saveAdminAction = saveAdmin;
  const [filters, setFilters] = useState(InitialFilters);
  const saveFilterDataAction = saveFilters;
  const [isFormValid, setFormValid] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const {
    details,
    loading,
    error
  } = useSelector(({ filter: { details, loading, error } }) => ({ details, loading, error }));

  const {
    details: loggedAdminDetails,
  } = useSelector(({ loggedAdmin: { details, loading, error } }) => ({ details, loading, error }));

  const {
    details: loggedUserAllRolesDetails,
  } = useSelector(({ loggedUserAllRoles: { details, loading, error } }) => ({ details, loading, error }));

	const handleSubmit = (e) => {
		e.preventDefault();
    const isFormValid = validate();

    if(isFormValid) {
      dispatch(saveFilterDataAction(filters));
      navigate("/");
    }
	}

  const validate = () => {
    let isFormValid = true;
    let isInvalidPOSIDError = false;
    let isInvalidLEError = false;

    if(emp_legal_entity_id === "") {
      isInvalidLEError = true;
      isFormValid = false;
    }

    setFormValid(isFormValid);
    return isFormValid;
  }

  const checkState = () => {
    if(office_id === "") {
      return true;
    }

    return false;
  }

  const {
    office_id = "",
    emp_legal_entity_id = "",
    emp_id_pattern = "",
    date = "",
    emp_legal_entity_name =  ""
  } = filters;

  useEffect(() => {
    if(emp_legal_entity_id !== "") {
      dispatch(getConfigurationByLE(emp_legal_entity_id));
      getLegalEntityName(emp_legal_entity_id);
    }
  }, [emp_legal_entity_id]);

  const getLegalEntityName = async (emp_legal_entity_id = "") => {
    setLoading(true);

    const obj = {};
    obj.legal_entity_id = emp_legal_entity_id;

    const URL=`${baseURL}/getAllDataByLE`;
    return await fetch(URL, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: JSON.stringify(obj),
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(res => res.json())
    .then((res) => {
      const legal_entity_name = res && res[0] ? res[0]?.legal_entity_name : "-";
      setLoading(false);
      setFilters({...filters, ["emp_legal_entity_name"]: legal_entity_name});
    })
    .catch((error) => {
      console.log('error getLegalEntityName', error);
      setLoading(false);
    });
  }

  const handleLEChange = (value) => {
    const selectedUserDetails = getUserDetailsByLE(value);
    if(!isManager(selectedUserDetails)) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        emp_legal_entity_id: value,
        office_id: "all",
        emp_id_pattern: "all",
      }));
      dispatch(getAllOfficesByLegalEntity(value));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        emp_legal_entity_id: value,
        office_id: selectedUserDetails.emp_base_office_id ? selectedUserDetails?.emp_base_office_id : "all",
        emp_id_pattern: "all",
      }));
      dispatch(getOfficesFromOfficeID(selectedUserDetails?.emp_base_office_id));
    }
  }

  useEffect(() => {
    setFilters(details);
  }, [details]);

  const getUserDetailsByLE = (selected_LE = "") => {
    const filteredUserDetails = loggedUserAllRolesDetails.filter((eachRole, idx) => eachRole.legal_entity_id === selected_LE);
    const userDetails = filteredUserDetails?.[0];

    if(userDetails) {
      dispatch(saveAdminAction(userDetails));
      return userDetails
    }
    
    return null; 
  }

	return (
    <>
      <Helmet>
        <title>{titleVal}</title>
        <meta name="description" content="Login meta"></meta>
        <link rel="canonical" href={hrefVal} />
      </Helmet>

      <div className="login">
        <div className="wave">
          <img src={wave} alt = "" />
        </div>
        
        <div className="pcc_logo_container">
          <img src={pcc_logo} alt = "" className="pcc_logo" />
          <div className="ps-3 powered_by_text"><span className="powered_by">powered by</span> <span className="fw-bold smmc_text">SMM:C</span></div>
        </div>

        <div className="footer-text">© SMM:C · Contact · Privacy & terms</div>
        <div className="login_container">
          <div className="login-content flex-column">
            <div className="d-flex align-items-center my-3">
                <span className="pe-2 logo_black inter_regular">Welcome to </span>
                <div className="logo_black"><span className="logo_red">One</span> Attendance</div> 
            </div>
            <div className="mt-5">
              <div className="d-flex align-items-center">
                <img src={ofc_icon} className="pe-3 scale" alt = "" /> 
                <div>
                  <div className="dialog_h2_heading">Select Organization</div>
                </div>

              </div>
            </div>
           
            <div className="mt-5 mb-3">
              <div className="mb-2">Legal Entity</div>
              <LESelectView 
                name="emp_legal_entity_id" 
                isFullWidth = "75%" 
                value={emp_legal_entity_id} 
                setUpdateValue={(name, value) => handleLEChange(value)}
              />
            </div>

            <div className="my-3">
              <button 
                className="btn btn-danger px-lg-5 my-3" 
                type="submit" 
                disabled={checkState()} onClick={handleSubmit}
              >
                Next
              </button>
            </div>
          </div>
         
        </div>
      </div>

      {isLoading && <Loading />}
    </>
	)
};

export default ConfigureDevice;