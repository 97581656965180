import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import CustomizedSnackbars from '../../components/CustomizedSnackbars';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Loading from '../../components/loading/Loading';
import {baseURL} from '../../actions/Constants';
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";

import emp_icon from '../../assets/images/emp.png';
import circle_logo from '../../assets/images/circle.png';

var XLSX = require("xlsx");

const AddBulkUsersDialog = (props) => {
	const { isOpen = false , le ="SMMC", onHandleClose, onUpdateSuccess} = props;

	const [open, setOpen] = useState(isOpen);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('md');
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const [file, setfile] = useState(null);
  const [data, setdata] = useState([]);

  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(false);
  const [filterBtn, setFilterBtn] = useState(false);
  const [selectedData, setSeletedData] = useState([]);

  const [failedArrayList, setFailedArrayList] = useState([]);

  const title = "";

  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectToolbarPlacement: 'above',
    searchAlwaysOpen: false,
    
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <div>
        {/*<button className="ax-button px-3" title='Details'>
          Send SMS
        </button>

        <button className="ax-button px-3" title='Details'>
          Send Whatsapp
        </button>

        <button className="ax-button px-3" title='Details'>
          Send SPECIAL VOUCHER
        </button>*/}
      </div>
    ),

    /*selectableRows: false,*/
    onTableChange: (action, state) => {
      
    },
    onRowsDelete: (rowsDeleted, newData) => {
     
    },
    onRowsSelect: (rowData, rowState) => {

      if(rowState.length === data.length) {
        setSeletedData(data);
      } else {
        const newData = [];
        const latestData = rowState.map((item, index) => {
          return data[item.index];
        });
        setSeletedData(latestData);
      }
    }
  };


  const [option, setOption] = useState(options);

  const muiCache = createCache({
    key: "mui-datatables",
    prepend: true
  });

  const columns = [
    {
      name: "emp_id",
      label: 'Emp ID',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "emp_name",
      label: 'Name',
      options: {
       filter: true,
       sort: true,
      }
    },
    {
      name: "emp_email",
      label: 'Email',
      options: {
       filter: true,
       sort: true,
      }
    },
    {
      name: "emp_gender",
      label: 'Gender',
      options: {
       filter: true,
       sort: true
      }
    },
    {
      name: "emp_phone_no",
      label: 'Phone #',
      options: {
       filter: true,
       sort: true,
      }
    },
    {
      name: "emp_role",
      label: 'Role',
      options: {
       filter: true,
       sort: true,
      }
    },
    {
      name: "emp_designation",
      label: 'Designation',
      options: {
       filter: true,
       sort: true
      }
    },
   
    {
      name: "manager_email",
      label: "Mgr Email",
      options: {
       filter: true,
       sort: true
      }
    },
    {
      name: "manager_phone_no",
      label: 'Mgr Phone #',
      options: {
       filter: true,
       sort: true
      }
    }
  ];

  const handleChange = (e) => {
    const files = e.target.files;
    if (files && files[0]) {
      setfile(files[0]);
      setdata([]);
    }
  }

  const handleFile = (e) => {
    e.preventDefault();

    if(data.length === 0) {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = (e) => {
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {
          type: rABS ? 'binary' : 'array',
          bookVBA: true,
        });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const datas = XLSX.utils.sheet_to_json(ws);

        const orgData = datas.map((user, index) => {
          const obj = {};
          
          obj.emp_id = user.emp_id?.toString();
          obj.emp_name = user.emp_name;
          obj.emp_email = user.emp_email;
          obj.emp_phone_no = user.emp_phone_no?.toString();
          obj.emp_role =  user.emp_role;
          obj.emp_designation = user.emp_designation;
          obj.emp_gender = user.emp_gender;
          obj.emp_manager_emp_id = user.emp_manager_emp_id?.toString();
          obj.legal_entity_id = user.legal_entity_id;
          obj.emp_base_office_id =  user.emp_base_office_id?.toString();
          obj.emp_permission_level = user.emp_permission_level?.toString();
          obj.emp_profile_pic = "";

          const configDetails = {};
          configDetails.auto_sign_in_out = user.auto_sign_in_out?.toString();
          configDetails.gps_tracking_on = user.gps_tracking_on;
          configDetails.attendance_method = user.attendance_method;
          configDetails.wfh_enabled = user.wfh_enabled;
          configDetails.overtime_enabled = user.overtime_enabled;
          configDetails.payroll = user.payroll;
          configDetails.jobrole = user.jobrole;
          configDetails.shift_in_time = user.shift_in_time?.toString();
          configDetails.shift_out_time = user.shift_out_time?.toString();
          obj.emp_configuration = configDetails;

          const accessListDetails =  [];
          const accessDetails = {};

          accessDetails.office_id = user.office_id?.toString() || "";
          accessDetails.office_name = user.office_name || "";
          accessDetails.office_address = user.office_address || "";
          accessDetails.access_given_on = user.access_given_on || "";
          accessDetails.access_given_by = user.access_given_by || "";
          accessDetails.base_office = user.base_office?.toString() || true;
          accessListDetails.push(accessDetails);

          const access_list_obj = {};
          access_list_obj.access_list = accessListDetails
          obj.emp_access_office_ids = access_list_obj;
          return obj;
        });

       // setdata(datas)
        setdata(orgData);
        //setOption({...option, rowsSelected: orgData});
        //setSeletedData(orgData)
      };
      if (rABS) {
        reader.readAsBinaryString(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
    } else {
      uploadUsers();
    }
  }

  const uploadUsers = async () => {
    await Promise.all(selectedData.map(async (item) => {
      const contents = await insertEmpDetails(item);
      if(failedArrayList.length === 0) {
        onHandleClose();
      }
    }));
  }

  const insertEmpDetails = async (emp) => {
    const URL= `${baseURL}/insertUser`;
    setLoading(true);

    const {
      emp_id = "",
      emp_name = "",
      emp_email = "",
      emp_phone_no = "",
      emp_role = "",
      emp_designation = "",
      emp_gender = "",
      emp_manager_emp_id = "",
      legal_entity_id = "",
      emp_access_office_ids = "",
      emp_base_office_id = "",
      emp_access_level = "",
      emp_configuration = {
        auto_sign_in_out : false,
        gps_tracking_on : false,
        attendance_method : "",
        wfh_enabled : false,
        overtime_enabled : true,
        payroll : "",
        jobrole : "",
        shift_in_time : null,
        shift_out_time : null
      },
      emp_permission_level = "",
      emp_profile_pic = ""
    } = emp || {};

    if(emp_access_office_ids !== "") {
      emp.emp_access_office_ids = JSON.stringify(emp_access_office_ids);
    }

    if(emp_configuration !== "") {
      emp.emp_configuration = JSON.stringify(emp_configuration);
    }

    return await fetch(URL, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: JSON.stringify(emp), // body data type must match "Content-Type" header
      headers: {
        'Content-Type': 'application/json',
      }
    })
    //.then(res => res.json())
    .then((res) => {
      //Close Dialog
      setLoading(false);
      setError(false);
      if(res === "FAIL") {
        setFailedArrayList([...failedArrayList, emp]);
      } else {
        //console.log("Success");
      }
    })
    .catch((error) => {
      console.log('error insertUser', error);
      setLoading(false);
      setError(true);
      failedArrayList.push(emp);
    });
  }

	return (
    <>
      {isLoading ? <Loading /> :
        (

        <Dialog 
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={isOpen}
            onClose={onHandleClose}
            aria-label=""
            className="md"
        >
            <div className="container p-5">
              <div className="d-flex align-items-center">
                <img src={circle_logo} className="circle_logo" />
                <div className="logo_black ps-3"><span className="logo_red">One</span> Attendance</div> 
              </div>
              
              <div className="d-flex align-items-center justify-content-between">
                           
                <div className="d-flex align-items-center">
                  <div className="py-3"> 
                    <img src={emp_icon} className="pe-3" /> 
                  </div>

                  <div>
                    <div className="dialog_h2_heading">Add Bulk Users</div>
                    <div className="dialogSubText mt-3">
                      <div>Please choose the information data of users,</div>
                      <div>you want to update</div>
                    </div>
                  </div>
                </div>
              </div>

              <form onSubmit={(e) =>handleFile(e)}>    
                <div className="pt-3 px-2">
                	<div className="file_upload_container p-2">
                    <input
              				type="file"
              				className="form-control a11y"
                      id="file"
                      onChange={handleChange}
                      accept=".xls,.xlsx"
        				    />

                    <label for="file" className="btn btn-danger">Choose File</label>
                    <label className='px-5 opacity-75'>{file ? file.name: "No File Choosen"}</label>
                  </div>

        			    {data.length > 0 && <div className="py-5">
            			  <CacheProvider value={muiCache}>
                      <ThemeProvider theme={createTheme()}>
                        <MUIDataTable
                          title={title}
                          data={data}
                          columns={columns}
                          options={option}
                        />
                      </ThemeProvider>
                    </CacheProvider>
                  
                </div>}

        			    <div className="py-4 d-flex align-items-center justify-content-end">
                  	<button type="button" className="btn btn-outline-danger me-3 mt-3" onClick={onHandleClose}>Cancel</button>
                    <button type="submit" disabled={file === null || (data.length > 0 && selectedData.length === 0)} className="btn btn-danger mt-3 mr-3" id="file" > {data.length === 0 ? "Fetch" : "Upload"} </button>
                    
                	</div>
                </div>
              </form>
            </div>
        </Dialog>)
      }
      {isError && <CustomizedSnackbars showAlert = {(val) => setError(false)} />}
    </>
	)	
}

export default AddBulkUsersDialog;