const Legalentity_super_admin = [
    {
        id:1,
        isDisabled: false,
        value: 'Apex',
        label: "Apex"
    },
    {
        id:2,
        isDisabled: false,
        value: 'Goldlites',
        label: "Goldlites"
    },
    {
        id:3,
        isDisabled: false,
        value: 'Jewellers',
        label: "Jewellers"
    },
    {
        id:4,
        isDisabled: false,
        value: 'Sons',
        label: "Sons"
    }
];

const Legalentity_Admin = [
    {
        id:1,
        isDisabled: false,
        value: 'Apex',
        label: "Apex"
    },
    {
        id:2,
        isDisabled: false,
        value: 'Goldlites',
        label: "Goldlites"
    },
    {
        id:3,
        isDisabled: false,
        value: 'Jewellers',
        label: "Jewellers"
    }
];

const Legalentity_Apex = [
    {
        id:0,
        isDisabled: true,
        value: 'Apex',
        label: "Apex"
    }
];

const Legalentity_Goldlites = [
    {
        id:0,
        isDisabled: true,
        value: 'Goldlites',
        label: "Goldlites"
    }
];

const Legalentity_Jewellers = [
    {
        id:0,
        isDisabled: true,
        value: 'Jewellers',
        label: "Jewellers"
    }
];

const Legalentity_Sons = [
    {
        id:0,
        isDisabled: true,
        value: 'Sons',
        label: "Sons"
    }
];

const generateLEList = (data) => {
    const le_list = [];

    if(Array.isArray(data)) {
        const filterData = data.filter(item => item.emp_role.toLowerCase().includes('admin') || item.emp_role.toLowerCase().includes('manager')); //Amit - to take only admins LE
        //const filterData = data; //Amit - to take all LE value 
        
        filterData.map((item, index) => {
            const obj = {};
            obj.value = item.legal_entity_id;
            obj.label = item.legal_entity_id;
            le_list.push(obj);
        });
    }
    
    return le_list;
}

const isPhoneNumberValid = (no) => {
    const regex = /^[0-9][0-9]{9}$/;
    return regex.test(no);
}

const isEmailValid = (email) => {
  const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}


const Gender = [
    {
        label: "Male",
        value : "Male"
    },
    {
        label: "Female",
        value : "Female"
    },
    {
        label: "Neutral",
        value : "Neutral"
    }
]

const PermissionLevel = [
    {
        label: "1",
        value : "1"
    },
    {
        label: "2",
        value : "2"
    },
    {
        label: "3",
        value : "3"
    },
    {
        label: "4",
        value : "4"
    },
    {
        label: "5",
        value : "5"
    }
]

const Designation = [
    {
        label: "External connect",
        value : "External connect"
    },
    {
        label: "External",
        value : "External"
    },
    {
        label: "Partner",
        value : "Partner"
    },
    {
        label: "Executive",
        value : "Executive"
    },
    {
        label: "Other",
        value : "Other"
    }
]

const AttendanceType = [
    {
        label: "Wi-Fi",
        value : "Wi-Fi"
    }, 
    {
        label: "GPS",
        value : "GPS"
    },
    {
        label: "BLE",
        value : "BLE"
    },
    {
        label: "Any",
        value : "Any"
    },
    {
        label: "None",
        value : "None"
    },
];

const WeeklyOffDays = [
    {
        label: "Sunday",
        value : "Sunday"
    }, 
    {
        label: "Monday",
        value : "Monday"
    },
    {
        label: "Tuesday",
        value : "Tuesday"
    },
    {
        label: "Wednesday",
        value : "Wednesday"
    },
    {
        label: "Thursday",
        value : "Thursday"
    },
    {
        label: "Friday",
        value : "Friday"
    },
    {
        label: "Saturday",
        value : "Saturday"
    },
];

const isEmptyJson = (jsonToTest  = {}) => {
    return (JSON.stringify({}) == JSON.stringify(jsonToTest))
}

const getEpocFromMoment = (val) => {
   return val.getTime();
}


const getEpocFromMomentStartDate = (val) => {
    val.setHours(0,0,0,0);
    return val.getTime();
}

const validateUser = (emp = {}) => {
    const userRole = emp.emp_role.toLowerCase();
    const isAuthorizedRole = ['admin', 'sub-administrator', 'manager'].some(role => userRole.includes(role));
    return isAuthorizedRole;
};

const isManager = (emp = {}) => {
    if(!emp) return false;
    const userRole = emp.emp_role.toLowerCase();
    const isManager = ['manager'].some(role => userRole.includes(role));
    return isManager;
};

export {
    isEmptyJson,
    isPhoneNumberValid,
    isEmailValid,
    isManager,
    Designation, 
    Gender,
    getEpocFromMoment,
    getEpocFromMomentStartDate,
    WeeklyOffDays,
    AttendanceType,
    Legalentity_super_admin, 
    Legalentity_Admin, 
    Legalentity_Apex, 
    Legalentity_Goldlites, 
    Legalentity_Jewellers, 
    Legalentity_Sons, 
    generateLEList,
    validateUser
};